import React from 'react';
import { Layout, SEO, ContentSection } from '@egonoid/gatsby-theme-common';

import { graphql, Link } from 'gatsby';

import kebabCase from 'lodash/kebabCase';

interface IProps {
  data: any;
  location: any;
}

const TagsPage: React.FC<IProps> = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => (
  <Layout location={location} title={title} showLogo={true}>
    <SEO title={title} />
    <ContentSection>
      <h1>All Tags</h1>
    </ContentSection>
    <ContentSection>
      <ul>
        {group.map((tag) => (
          <li key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </ContentSection>
  </Layout>
);

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      filter: { frontmatter: { template: { eq: "post" } }, fields: { isVisible: { eq: true } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
